import { Injectable } from '@angular/core';
import {map, catchError} from 'rxjs/operators';
import { environment } from 'environments/environment';
import 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from '../../error.service';
import { User } from '../../user/user.model';
import { UserService } from '../../user/user.service';
import { AddNews } from './news/add-news-component/add-news.model';
import { Observable } from 'rxjs/Observable';
import { News } from './news/news.model';
import { ApiGetNewsResponse, ApiGetSingeNewsResponse } from './api/api-news-responses.model';
import { Page } from './page/list-page.component/Page.model';
import { ApiGetPageResponse, ApiGetPagesResponse } from './api/api-page-responses.model';
import { AddPage } from './page/add-page-component/AddPage.model';
import { AddSlider } from './slider/add/AddSlider.model';
import { Application, ApplicationGroup } from './applications/list-applications/application.model';
import { ApiApplication, ApiApplicationGroup, ApiFilesAssociatedProducts } from './api/api-applications-response.model';
import { File } from './uploader/file-list/file-list.component';

@Injectable()
export class ContentService {

  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  addPage(addPage: AddPage) {
    return this.http
      .post(this.apiUrl + '/content?langId=' + this.user.languageId, JSON.stringify(addPage), { headers: this.headers })
      .catch(this.errorService.handleError<any>('addNews'));
  }

  addSlider(addSlider: AddSlider) {
    return this.http
      .post(this.apiUrl + '/content?langId=' + this.user.languageId, JSON.stringify(addSlider), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('addNews')));
  }

  getPage(pageId: number) {
    return this.http.get(this.apiUrl + '/content/' + pageId + '?langId=' + this.user.languageId)
      .map((response) => {
        const page = (new ApiGetPageResponse(response)).getData();
        return new Page(page.id, page.name, page.title, page.meta_title, page.meta_keywords, page.meta_description, page.content, page.slug, page.guid,
          page.publicVisible != null ? page.publicVisible : false);
      })
      .catch(this.errorService.handleError<any>('getPage'));
  }


  getPages(): Observable<Page[]> {
    return this.http.get(this.apiUrl + '/content?langId=' + this.user.languageId)
      .map((response) => {
        const listPages: Page[] = [];
        for (const page of (new ApiGetPagesResponse(response)).getData()) {
          const row = new Page(
            page.id, page.name, page.title, page.meta_title, page.meta_keywords, page.meta_description, page.content, page.slug, page.guid, page.publicVisible != null ? page.publicVisible : false);
          listPages.push(row);
        }
        return listPages;
      })
      .catch(this.errorService.handleError<any>('getPages'));
  }

  updatePage(pageId: number, page: Page) {
    return this.http
      .put(this.apiUrl + '/content/' + pageId + '?langId=' + this.user.languageId,
        JSON.stringify(page), { headers: this.headers })
      .catch(this.errorService.handleError<any>('updatePage'));
  }

  deletePage(pageId: number) {
    return this.http
      .delete(this.apiUrl + '/content/' + pageId + '?langId=' + this.user.languageId, { headers: this.headers })
      .catch(this.errorService.handleError<any>('deletePage'));
  }


  getNews(): Observable<News[]> {
    return this.http.get(this.apiUrl + '/news?langId=' + this.user.languageId)
      .map((response) => {
        const listNews: News[] = [];
        for (const news of (new ApiGetNewsResponse(response)).getData()) {
          const row = new News(
            news.id, news.title, news.summary, news.content, news.publishedOn, news.validFrom,
            news.validUntil, news.isActive);
          listNews.push(row);
        }
        return listNews;
      })
      .catch(this.errorService.handleError<any>('getNews'));
  }

  getSingleNews(newsId: number): Observable<News> {
    return this.http.get(this.apiUrl + '/news/' + newsId + '?langId=' + this.user.languageId)
      .map((response) => {
        const news = (new ApiGetSingeNewsResponse(response)).getData();
        return new News(news.id, news.title, news.summary, news.content, news.publishedOn,
          news.validFrom, news.validUntil, news.isActive);
      })
      .catch(this.errorService.handleError<any>('getDistributor'));
  }

  addNews(addNews: AddNews): Observable<News> {
    return this.http
      .post(this.apiUrl + '/news?langId=' + this.user.languageId, JSON.stringify(addNews), { headers: this.headers })
      .catch(this.errorService.handleError<any>('addNews'));
  }


  deleteNews(newsId: number) {
    return this.http
      .delete(this.apiUrl + '/news/' + newsId + '?langId=' + this.user.languageId, { headers: this.headers })
      .catch(this.errorService.handleError<any>('deleteDistributor'));
  }

  updateNews(newsId: number, news: News) {
    return this.http
      .put(this.apiUrl + '/news/' + newsId + '?langId=' + this.user.languageId,
        JSON.stringify(news), { headers: this.headers })
      .catch(this.errorService.handleError<any>('updateDistributor'));
  }

  deleteApplication(applicationId: number) {
    return this.http.delete(this.apiUrl + '/usageItem/' + applicationId, { headers: this.headers })
      .catch(this.errorService.handleError<any>('deleteDistributor'));
  }

  getApplicationGroups() {
    return this.http.get<ApiApplicationGroup[]>(this.apiUrl + '/usages?langId=' + this.user.languageId)
      .map((response) => {
        const listApplicationGroups: ApplicationGroup[] = [];

        for (const applicationGroup of response) {
          const applications: Application[] = [];
          for (const application of applicationGroup.items) {
            applications.push({
              id: application.id,
              title: application.title,
              slug: application.slug,
              content: application.content,
              galleryId: application.gallery_id,
              applicationGroupId: application.usage_id,
              sortOrder: application.sortOrder
            })
          }
          const row = new ApplicationGroup(applicationGroup.id, applicationGroup.title, applicationGroup.content, applicationGroup.slug, applications);
          listApplicationGroups.push(row);
        }
        return listApplicationGroups;
      })
      .catch(this.errorService.handleError<any>('getApplications'));
  }

  addApplicationGroup(applicationGroup: ApplicationGroup) {
    return this.http
      .post(this.apiUrl + '/usages?langId=' + this.user.languageId, JSON.stringify(applicationGroup), { headers: this.headers })
      .catch(this.errorService.handleError<any>('addNews'));
  }

  getApplicationGroup(applicationGroupId: number): Observable<ApplicationGroup> {
    return this.http.get<ApiApplicationGroup>(this.apiUrl + '/usages/' + applicationGroupId + '?langId=' + this.user.languageId)
      .map((response) => {
        const applications: Application[] = [];
        for (const application of response.items) {
          applications.push({
            id: application.id,
            title: application.title,
            slug: application.slug,
            content: application.content,
            galleryId: application.gallery_id,
            applicationGroupId: application.usage_id,
            sortOrder: application.sortOrder
          })
        }
        return new ApplicationGroup(response.id, response.title, response.content, response.slug, applications);
      })
      .catch(this.errorService.handleError<any>('getApplicationGroup'));
  }

  updateApplicationGroup(id: number, data: { title: string; content: string }) {
    return this.http.put<ApiApplicationGroup>(this.apiUrl + '/usages/' + id + '?langId=' + this.user.languageId,
      JSON.stringify(data), { headers: this.headers })
      .map((response) => {
        return new ApplicationGroup(response.id, response.title, response.content, response.slug, null);
      })
  }

  addApplication(applicationGroupId: number, title: string): Observable<Application> {
    return this.http.post<ApiApplication>(this.apiUrl + '/usages/' + applicationGroupId + '/item?langId=' + this.user.languageId,
      JSON.stringify({ title: title }), { headers: this.headers })
      .map((application) => {
        return {
          id: application.id,
          title: application.title,
          content: application.content,
          galleryId: application.gallery_id,
          applicationGroupId: application.usage_id,
          sortOrder: application.sortOrder
        }
      })
      .catch(this.errorService.handleError<any>('getApplication'));
  }

  getAssociatedProductsByFilename(fileName: string): any {
    return this.http.post<ApiFilesAssociatedProducts>(this.apiUrl + '/products/getByFiles', { filename: fileName }, { headers: this.headers }).map(response => {
      return response
    }).catch(this.errorService.handleError<any>('getAssociatedProductsByFilename'));
  }


  updateApplication(applicationId: number, data: { title: string; content: string, slug: string }) {
    return this.http
      .put(this.apiUrl + '/usageItem/' + applicationId + '?langId=' + this.user.languageId,
        JSON.stringify(data), { headers: this.headers })
      .catch(this.errorService.handleError<any>('updateApplication'));
  }

  getContentFiles(): Observable<File[]> {
    return this.http.get(this.apiUrl + '/contentfiles?langId=' + this.user.languageId)
      .catch(this.errorService.handleError<any>('getContentFiles'));
  }

  getPhotosApplication(): Observable<File[]> {
    return this.http.get(this.apiUrl + '/photosapplication?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getPhotosApplication')));
  }


  deleteContentFile(fileName: string) {
    if (confirm('Do you really want to delete this file ? (related product documents may be deleted)')) {
      return this.http
        .post(this.apiUrl + '/contentfiles', { filename: fileName }, { headers: this.headers })
        .catch(this.errorService.handleError<any>('deleteDistributor'));
    }
  }
}
